import { render, staticRenderFns } from "./PrizeDrawRegisterPrizes.vue?vue&type=template&id=e9458cac&scoped=true&"
import script from "./PrizeDrawRegisterPrizes.vue?vue&type=script&lang=js&"
export * from "./PrizeDrawRegisterPrizes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9458cac",
  null
  
)

export default component.exports