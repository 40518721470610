<template>
  <b-card-code>
    <page-header
      name="Sorteios"
    >
      <b-button
        variant="primary"
        @click="newPrizeDraw"
      >
        <FeatherIcon icon="PlusIcon" />
        &nbsp;<span class="text-nowrap">Adicionar</span>
      </b-button>
    </page-header>

    <prize-draw-form
      :is-active.sync="isActivated"
      :prize-draw="current"
      @onModelSave="onSave()"
      @onCancel="onCancelEdit()"
    />

    <i2-side-bar
      :is-active.sync="isUploadActivated"
      window-title="Importar Arquivo"
    >
      <prize-draw-file-import
        :is-active.sync="isUploadActivated"
        :prize-draw="current"
        @onModelSave="onSave()"
        @onCancel="onCancelEdit()"
      />
    </i2-side-bar>

    <PrizeDrawRegisterPrizes v-model="isRegisterPrizes" :prize-draw="current" @onClose="closeRegisterPrizes()"/>

    <PrizeDrawSegment :prize-draw="current" :is-open="isRegisterSegments" @onClose="closeSegments" />

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template
        #cell(name)="data"
      >
        <b>ID:</b> {{ data.item.uuid }}<br/>
        <b>Nome:</b> {{ data.item.name }}
      </template>
      <template
        #cell(promotion)="data"
      >
        {{ data.item.promotion.name }}
        <b-badge v-if="data.item.status === 2" variant="success">
          Sorteio Homologado
        </b-badge>
        <div v-if="data.item.promotion.settings.is_instagram_promotion">
          <b-badge variant="primary">
            Promoção para Instagram
          </b-badge>
        </div>
      </template>
      <template
        #cell(configuration)="data"
      >
        <b>Data Inicial:</b> {{ data.item.start_date }}<br>
        <b>Data Final:</b> {{ data.item.end_date }}<br>
        <b>Data do Sorteio:</b> {{ data.item.draw_date }}<br>
        <b>Vencedores:</b> {{ data.item.total_winners }}<br>
        <b>Serie:</b> {{ data.item.settings.start_serie }} a {{ data.item.settings.end_serie }}<br>
        <b>Prêmios:</b> {{ data.item.total_prizes || 0 }}<br>
        <span v-if="data.item.segments.length > 0">
          <b>Segmentos:</b>
          <b-badge class="ml-1" variant="primary">
            <span> Total: {{ data.item.segments.length }}</span>
          </b-badge>
          <br>
        </span>
      </template>
      <template
        #cell(action)="data"
      >
        <b-button-group justify>
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            @click="setCurrent(data.item)"
          />
          <i2-icon-button
            v-if="data.item.promotion.settings.is_instagram_promotion"
            icon="UsersIcon"
            variant="outline-primary"
            @click="setUploadComments(data.item)"
          />

          <i2-icon-button
            icon="FilterIcon"
            variant="outline-primary"
            @click="setFilters(data.item)"
          />

          <i2-icon-button
            icon="AwardIcon"
            variant="outline-primary"
            @click="setPrizeDrawRegisterPrizes(data.item)"
            @close="closeRegisterPrizes"
          />
        </b-button-group>

      </template>
    </Grid>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import userType from '@/variables/userTypes'
import PrizeDrawForm from '@/views/prize-draw/PrizeDrawForm.vue'
import PrizeDrawFileImport from '@/views/prize-draw/PrizeDrawFileImport'
import PrizeDrawRegisterPrizes from '@/views/prize-draw/PrizeDrawRegisterPrizes.vue'
import PrizeDrawSegment from '@/components/app/prize-draw/PrizeDrawSegment.vue'

export default {
  components: {
    PrizeDrawSegment,
    PrizeDrawRegisterPrizes,
    PrizeDrawFileImport,
    PrizeDrawForm,
    I2SideBar,
    Grid,
    BCardCode,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Informações',
          class: 'w-30',
        },
        {
          key: 'promotion',
          label: 'Promoção',
          class: 'w-20',
        },
        {
          key: 'configuration',
          label: 'Configurações',
          class: 'w-30',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-30 text-center',
        },
      ],
      items: [],
      current: {},
      isActivated: false,
      isUploadActivated: false,
      isRegisterPrizes: false,
      isRegisterSegments: false,
      userType,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.current = {}
      }
    },
  },
  mounted() {
    this.getPrizeDraws()
  },
  methods: {
    async getPrizeDraws() {
      const response = await this.$http.get('prize-draws')
      this.items = response || []
    },
    setCurrent(promotion) {
      this.current = { ...promotion }
      this.isActivated = true
    },
    setUploadComments(promotion) {
      this.current = { ...promotion }
      this.isUploadActivated = true
    },
    newPrizeDraw() {
      this.current = {}
      this.isActivated = true
    },
    onSave() {
      this.current = {}
      this.isActivated = false
      this.isUploadActivated = false
      this.isRegisterPrizes = false
      this.getPrizeDraws()
    },
    onCancelEdit() {
      this.isActivated = false
      this.isUploadActivated = false
      this.isRegisterPrizes = false
    },
    setPrizeDrawRegisterPrizes(prizeDraw) {
      this.isRegisterPrizes = true
      this.current = { ...prizeDraw }
    },
    closeRegisterPrizes() {
      this.isRegisterPrizes = false
      this.current = {}
    },
    setFilters(prizeDraw) {
      this.isRegisterSegments = true
      this.current = prizeDraw
    },
    closeSegments() {
      this.isRegisterSegments = false
      this.current = {}
    },
  },
}
</script>
<style>

</style>
